import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  content: {
    width: '100%',
    padding: spacing(32, 50),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    maxWidth: 1000,
    display: 'grid',
    gap: spacing(40, 50),
  },
  sectionTitle: {
    fontSize: spacing(22),
    fontWeight: 500,
    lineHeight: spacing(20),
    color: Theme.Colors.Primary.Base,
  },
  detailLabel: {
    fontSize: spacing(10.5),
    color: '#9F9F9F',
  },
  tooltipIcon: {
    marginLeft: '8px',
    fontSize: '2.4rem',
    fontWeight: '500',
    lineHeight: '2.4rem',
    fill: palette.gray[64],
  },

  tooltipText: {
    fontSize: '1.4rem',
    color: 'white',
    textAlign: 'right',
  },
  supportIcon: {
    objectFit: 'contain',
    fontSize: '2rem',
  },
  buttonSupportIcon: {
    marginLeft: spacing(6),
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  required: {
    color: Theme.Colors.Red.Base,
    marginLeft: spacing(4),
  },
  saasAutoSendEmailCheckbox: {
    '&::after': {
      content: '"(* campo válido só para processo do SAAS)"',
      color: palette.gray[64],
      fontStyle: 'italic',
      fontSize: '1.1rem',
      marginTop: spacing(-8),
    },
  },
}))
