import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Button, Toast, Alert, Loader, Dialog } from '@refera/ui-web'
import { Danger as InfoDangerIcon, TickCircle as InfoSuccessIcon } from '@refera/ui-icons'
import useStyles from './styles'
import HeaderTitle from '_/components/header-title'
import { FormProvider, useForm } from 'react-hook-form'
import { navigate, useParams } from '@reach/router'

/* Redux */
import { useDispatch, useSelector } from 'react-redux'
import { agenciesResultSelector } from '_/modules/agency/selectors'
import { getAgencies, getAgenciesNextPage } from '_/modules/agency/actions'
import { getAllStepStatusOptionsSelector } from '_/modules/service-orders/selectors'
import { getStepStatusOptions } from '_/modules/service-orders/actions'
import { roleGroupListSelector } from '_/modules/utils/selectors'
import { getAllRoleGroups } from '_/modules/utils/actions'
import { businessFrontListSelector } from '_/modules/businessFront/selectors'
import { getBusinessFronts, getUserAgencyFlows } from '_/modules/businessFront/actions'
import { setItemsSelector, setItemsAsListSelector } from '_/modules/mensageria/selector'
import {
  deleteMessaging,
  getMessagingDetails,
  getSetItems,
  patchMessaging,
  postMessaging,
} from '_/modules/mensageria/actions'

import Svg from '_components/svg'
import QuestionIcon from '_assets/svgs/question-circle-blue.svg'

import {
  generalGridAreaTemplate,
  getValueByReference,
  menuProps,
  complementGridAreaTemplates,
} from './utils'
import { ROUTES } from '_/utils/constants'
import { Set } from 'immutable'

import {
  Select,
  MultilineInput,
  Autocomplete,
  BasicInput,
  Checkbox,
  HTMLEditor,
} from '_components/inputs'

import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import RadioSelect from '_components/radio-select'
import { SectionTitle } from './components/SectionTitle'
import { userSelector } from '_/modules/authentication/selectors'
import { useToast } from '_/hooks/use-toast'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import useRolePermission from '_/hooks/use-role-permission'
import { IconButton, Tooltip, Typography } from '@material-ui/core'

const defaultDialog = {
  isOpen: false,
  subject: '',
  icon: InfoDangerIcon,
  iconType: 'info',
  description: '',
  labelApprove: 'Ok, entendi',
  onApprove: () => {},
}

function SupportButton() {
  const styles = useStyles()

  return (
    <Tooltip title="Ajuda" placement="bottom" PopperProps={{ style: { marginTop: -7 } }}>
      <IconButton
        href="https://refera.movidesk.com/kb/pt-br/article/505933/o-que-sao-as-variaveis-de-mensageria"
        component="a"
        target="_blank"
        className={styles.buttonSupportIcon}
      >
        <Svg icon={QuestionIcon} className={styles.supportIcon} />
      </IconButton>
    </Tooltip>
  )
}

function EmailSection({ messageDetails }) {
  const styles = useStyles()

  return (
    <>
      <SectionTitle title="Email" style={{ gridArea: 'emailSectionTitle' }} />
      <Checkbox
        id="saasAutoSendEmail"
        label="Envio automático"
        name="saasAutoSendEmail"
        defaultValue={messageDetails?.saasAutoSendEmail ?? true}
        style={{
          gridArea: 'saasAutoSendEmail',
          marginTop: '-20px',
        }}
        containerClasses={styles.saasAutoSendEmailCheckbox}
      />
      <BasicInput
        label={
          <div style={{ display: 'flex', alignItems: 'center', width: 'fit-contain' }}>
            <span>Assunto</span>
            <span className={styles.required}>*</span>
            {SupportButton()}
          </div>
        }
        showLabelSpan={false}
        name="email_subject"
        required
        defaultValue={messageDetails?.emailSubject ?? null}
        rules={{ maxLength: 255 }}
        style={{ gridArea: 'emailSubject' }}
      />
      <BasicInput
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Cópia Oculta</span>
            <Tooltip
              arrow
              disableFocusListener
              disableTouchListener
              title={
                <Typography className={styles.tooltipText}>
                  Envie esta mensagem como cópia oculta para o(s) endereço(s) neste campo. Utilize
                  vírgula (,) para configurar mais de um endereço.
                </Typography>
              }
              placement="top-start"
            >
              <HelpOutlineOutlinedIcon className={styles.tooltipIcon} />
            </Tooltip>
          </div>
        }
        name="hidden_copy"
        defaultValue={messageDetails?.hiddenCopy ?? null}
        rules={{ maxLength: 255 }}
        style={{ gridArea: 'emailBcc' }}
      />
      <HTMLEditor
        label={
          <div style={{ display: 'flex', alignItems: 'center', width: 'fit-contain' }}>
            <span>Corpo</span>
            <span className={styles.required}>*</span>
            {SupportButton()}
          </div>
        }
        showLabelSpan={false}
        name="email_body"
        required
        style={{ gridArea: 'emailBody' }}
        defaultValue={messageDetails?.emailBodyJson ?? null}
      />
    </>
  )
}

function WhatsAppSection({ messageDetails, selectedType }) {
  const styles = useStyles()

  return (
    <>
      <SectionTitle title="Whatsapp" style={{ gridArea: 'whatsappSectionTitle' }} />
      <BasicInput
        label="Nome do template"
        name="template_name"
        required
        defaultValue={messageDetails?.templateName ?? null}
        style={{ gridArea: 'whatsappTemplateName' }}
        rules={{ maxLength: 100 }}
      />
      {selectedType === 'step' && (
        <BasicInput
          label="Id do template"
          name="template_id"
          defaultValue={messageDetails?.templateId ?? null}
          style={{ gridArea: 'whatsappTemplateId' }}
          rules={{ maxLength: 100 }}
        />
      )}
      <MultilineInput
        label={
          <div style={{ display: 'flex', alignItems: 'center', width: 'fit-contain' }}>
            <span>Mensagem 1</span>
            <span className={styles.required}>*</span>
            {SupportButton()}
          </div>
        }
        showLabelSpan={false}
        required
        name="whatsapp_text"
        defaultValue={messageDetails?.whatsappText ?? null}
        style={{ gridArea: 'whatsappBody' }}
      />
      {selectedType !== 'step' && selectedType !== 'early_warning' && (
        <>
          <MultilineInput
            label="Mensagem 1 - Orçamento subsequente"
            required
            name="subsequent_budget_message_1"
            defaultValue={messageDetails?.subsequentBudgetMessage1 ?? null}
            style={{ gridArea: 'message_1_subsequent_budget' }}
          />
          <MultilineInput
            label="Mensagem 2 - Orçamento subsequente"
            required
            name="subsequent_budget_message_2"
            defaultValue={messageDetails?.subsequentBudgetMessage2 ?? null}
            style={{ gridArea: 'message_2_subsequent_budget' }}
          />
        </>
      )}
      {selectedType !== 'step' && (
        <MultilineInput
          label={
            <div style={{ display: 'flex', alignItems: 'center', width: 'fit-contain' }}>
              <span>Mensagem 2</span>
              <span className={styles.required}>*</span>
              <SupportButton />
            </div>
          }
          showLabelSpan={false}
          required
          name="message_2"
          defaultValue={messageDetails?.message2 ?? null}
          style={{ gridArea: 'message_2' }}
        />
      )}
    </>
  )
}

export function StepNotificationMessage() {
  const { messageId } = useParams()

  const { checkIfUserDoesNotPermission, isSAAS: isSaasProfile } = useRolePermission()

  /* React states */
  const [gridTemplateAreas, setGridTemplateAreas] = useState(generalGridAreaTemplate)
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [messageDetails, setMessageDetails] = useState({})
  const [dialogInfo, setDialogInfo] = useState(defaultDialog)

  /* Redux selectors */
  const dispatch = useDispatch()
  const agencies = useSelector(agenciesResultSelector)
  const user = useSelector(userSelector)
  const stepStatusList = useSelector(getAllStepStatusOptionsSelector)
  const roleGroupList = useSelector(roleGroupListSelector)
  const setItems = useSelector(setItemsSelector)
  const setItemsList = useSelector(setItemsAsListSelector)
  const businessFrontList = useSelector(businessFrontListSelector)
  const styles = useStyles()
  const [typeSelected, setTypeSelected] = useState()
  const [stepStatusValue, setStepStatusValue] = useState()
  const { showToast } = useToast()

  useEffect(() => {
    if (messageDetails) {
      setTypeSelected(messageDetails?.typeMessage)
      setStepStatusValue(messageDetails?.stepStatus?.desc)
    }
  }, [messageDetails])

  const typeMessageOptions = useMemo(() => {
    let options = [
      { name: 'step', description: 'Etapa' },
      { name: 'self_checkout', description: 'Self-checkout' },
      { name: 'early_warning', description: 'Aviso prévio' },
    ]

    if (isSaasProfile) {
      options = options.filter(option => option.name !== 'self_checkout')
    }

    return options
  }, [isSaasProfile])

  const roleGroupOptions = useMemo(() => {
    let options = roleGroupList?.slice() || []

    if (!options.length) {
      return options
    }

    if (isSaasProfile) {
      options = options.filter(option => option.roleGroupName !== 'refera_group')
    }

    return options?.sort((a, b) => a.roleGroupDesc.localeCompare(b.roleGroupDesc))
  }, [isSaasProfile, roleGroupList])

  const setItemOptions = useMemo(() => {
    const options = setItemsList?.slice() || []

    if (!options.length) return options

    if (typeSelected === 'self_checkout' || typeSelected === 'early_warning') {
      return options.filter(option => option.value === 'whatsapp')
    }

    return options?.sort((a, b) => a.label.localeCompare(b.label)) || []
  }, [setItemsList, typeSelected])

  const handleTypeChange = newValue => {
    if (newValue === 'step') {
      setTypeSelected(newValue)
      setStepStatusValue(messageDetails?.stepStatus?.desc)
    } else if (newValue === 'self_checkout') {
      setTypeSelected(newValue)
      setStepStatusValue(null)
    } else if (newValue === 'early_warning') {
      setTypeSelected(newValue)
      setStepStatusValue(null)
    }
  }

  const isDisabled = typeSelected === 'self_checkout' || typeSelected === 'early_warning'

  const methods = useForm({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: {
      business_front: messageDetails?.businessFront ?? [],
      type_message: messageDetails?.typeMessage ?? '',
      destination: messageDetails?.destination?.name ?? '',
      step_status: messageDetails?.stepStatus?.desc ?? '',
      agencies: messageDetails?.agencies ?? [],
      set_item: messageDetails?.setItem ?? '',
      default: messageDetails?.default ?? false,
      email_subject: messageDetails?.emailSubject ?? '',
      hidden_copy: messageDetails?.hiddenCopy ?? '',
      template_name: messageDetails?.templateName ?? '',
      template_id: messageDetails?.templateId ?? '',
      whatsapp_text: messageDetails?.whatsappText ?? '',
      message_2: messageDetails?.message2 ?? '',
      subsequent_budget_message_1: messageDetails?.subsequentBudgetMessage1 ?? '',
      subsequent_budget_message_2: messageDetails?.subsequentBudgetMessage2 ?? '',
      saasAutoSendEmail: messageDetails?.saasAutoSendEmail ?? false,
    },
  })

  const { handleSubmit, watch, reset } = methods

  const setItemDefaultValue = useMemo(() => {
    if (typeSelected === 'self_checkout' || typeSelected === 'early_warning') return 'WhatsApp'

    return messageDetails?.setItem ?? null
  }, [messageDetails?.setItem, typeSelected])

  const defaultCheckboxDefaultValue = useMemo(() => {
    if (isSaasProfile) return false
    return messageDetails?.default ?? false
  }, [messageDetails?.default])

  const agenciesDefaultValue = useMemo(() => {
    if (isSaasProfile && !messageId) {
      return agencies.filter(agency => agency.id === user?.agency)
    }
    return messageDetails?.agencies ?? []
  }, [agencies, user?.agency, isSaasProfile, messageDetails?.agencies])

  // 0: email, 1: whatsapp, 2: email + whatsapp
  const showSectionIndex = useMemo(() => {
    const valueToCompare = methods.getValues('set_item')

    const index = setItemsList.findIndex(item => item.label === valueToCompare)

    if (index < 0 || index > 2) {
      return -1
    }

    setGridTemplateAreas(generalGridAreaTemplate.concat(complementGridAreaTemplates[index]))
    return index
  }, [setItemsList, watch('set_item')])

  const disableAgencies = useMemo(() => {
    const defaultItem = methods.getValues('default')
    return isSaasProfile || !!defaultItem
  }, [isSaasProfile, watch('default')])

  const handleCloseToast = () => setToast(prevState => ({ ...prevState, isOpen: false }))

  const onSubmit = useCallback(
    data => {
      if (checkIfUserDoesNotPermission(PERMISSIONS.MESSENGER, PERMISSIONS_ACTIONS.ADD)) {
        return
      }

      setIsLoading(true)

      const b2cDescriptionName = getValueByReference({
        reference: businessFrontList,
        target: 'name',
        value: 'business_front_b2c',
        method: 'findInObjectList',
      })?.description

      if (data.business_front === b2cDescriptionName && data.destination === 'intermediary_group') {
        setToast({
          isOpen: true,
          message: 'Você não pode definir uma mensagem para intermediária no b2c.',
          type: 'error',
        })
        setIsLoading(false)
        return
      }

      const {
        agencies: dataAgencies,
        business_front: dataBusinessFront,
        step_status: dataStepStatus,
        set_item: dataSetItem,
        email_body: dataEmailBody,
      } = data

      const stepStatusName = getValueByReference({
        reference: stepStatusList,
        target: 'desc',
        value: dataStepStatus,
        method: 'findInObjectList',
      })?.name

      const dataSetItemKey = getValueByReference({
        reference: setItems,
        value: dataSetItem,
        method: 'findKeyfromValue',
      })

      const payload = {
        ...data,
        agencies:
          !dataAgencies || (disableAgencies && !isSaasProfile)
            ? []
            : Set(dataAgencies.map(agency => agency.id))
                .toList()
                .toJS(),
        step_status: stepStatusName,
        business_front: dataBusinessFront.map(item => item?.id),
        set_item: dataSetItemKey,
        email_body: dataEmailBody?.html ?? null,
        email_body_json: dataEmailBody?.json ?? null,
        type_message: typeSelected,
      }
      if (typeSelected !== 'step') {
        payload.templateId = null
      }

      if (dataSetItemKey === 'email') {
        try {
          delete payload.template_name
          delete payload.whatsapp_text
        } catch (e) {
          console.warn(e)
        }
      }

      if (dataSetItemKey === 'whatsapp') {
        try {
          delete payload.email_subject
          delete payload.hidden_copy
          delete payload.email_body
          delete payload.email_body_json
        } catch (e) {
          console.warn(e)
        }
      }

      if (!messageId) {
        dispatch(postMessaging(payload))
          .then(() => {
            reset()
            showToast({
              message: 'Mensagem cadastrada com sucesso.',
              type: 'success',
            })
          })
          .catch(error => {
            showToast({
              message:
                error?.error_message ??
                'Não foi possível gravar esse registro. Tente novamente mais tarde.',
              type: 'error',
            })
          })
          .finally(() => {
            setIsLoading(false)
          })

        return
      }

      dispatch(patchMessaging(messageId, payload))
        .then(({ agencies: resAgencies, ...rest }) => {
          setMessageDetails({
            ...rest,
            agencies: resAgencies.map(agency => ({ id: agency.id, name: agency.name })),
          })
          showToast({
            message: 'Mensagem atualizada com sucesso.',
            type: 'success',
          })
        })
        .catch(error => {
          if (error?.error_message === '<' || !error?.error_message) {
            return showToast({
              message: 'Não foi possível gravar esse registro. Tente novamente mais tarde.',
              type: 'error',
            })
          }

          return showToast({
            message: error?.error_message,
            type: 'error',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [
      businessFrontList?.length,
      setItemsList?.length,
      stepStatusList?.length,
      disableAgencies,
      messageId,
      typeSelected,
      showToast,
    ]
  )

  const handleDelete = useCallback(() => {
    if (checkIfUserDoesNotPermission(PERMISSIONS.MESSENGER, PERMISSIONS_ACTIONS.DELETE)) {
      return null
    }

    return setDialogInfo({
      isOpen: true,
      iconType: 'warning',
      icon: InfoDangerIcon,
      labelApprove: 'Sim',
      labelCancel: 'Não',
      subject: 'Aviso',
      description: 'Você está prestes a deletar essa mensagem! Deseja proseguir com a operação?',
      onApprove: () => {
        setIsLoading(true)
        dispatch(deleteMessaging(messageId))
          .then(() => {
            setIsLoading(false)
            setDialogInfo({
              isOpen: true,
              iconType: 'success',
              icon: InfoSuccessIcon,
              labelApprove: 'Ok',
              subject: 'Sucesso',
              description: 'A mensagem foi deletada com sucesso',
              onApprove: () => navigate(ROUTES.STEP_MESSAGE),
            })
          })
          .catch(err => {
            setIsLoading(false)
            console.warn(err)
            setDialogInfo({
              isOpen: true,
              iconType: 'error',
              icon: InfoDangerIcon,
              labelApprove: 'Ok',
              subject: 'Erro',
              description: 'Ocorreu um erro ao deletar a mensagem',
              onApprove: () => setDialogInfo(prev => ({ ...prev, isOpen: false })),
            })
          })
      },
      onCancel: () => setDialogInfo(prev => ({ ...prev, isOpen: false })),
    })
  }, [dispatch, messageId, navigate])

  /**
   * Returns an array of promises that are resolved when data is fetched.
   * @returns {Array} An array of promises.
   */
  function getListOfParameterFetches() {
    // Define an array of parameters, where each parameter is an array with two elements:
    // the first element is the data to be fetched, and the second element is the function that fetches the data.
    const parameters = [
      [agencies, () => loadAllAgencies()],
      [stepStatusList, () => dispatch(getStepStatusOptions())],
      [roleGroupList, () => dispatch(getAllRoleGroups())],
      [
        businessFrontList,
        () => (isSaasProfile ? dispatch(getUserAgencyFlows()) : dispatch(getBusinessFronts())),
      ],
      [setItemsList, () => dispatch(getSetItems())],
    ]

    // Define an empty array to store promises.
    const promises = []

    // Iterate through the parameters array and check if the data is empty.
    // If the data is empty, push the fetch function to the promises array.
    parameters.forEach(([key, value]) => {
      if (key?.length) return
      if ((Array.isArray(key) && key.length === 0) || !key?.toJS()?.length || !key) {
        promises.push(value)
      }
    })
    return promises
  }

  /**
   * Asynchronously loads the next page of agencies and recursively calls itself if there is a next page.
   * @returns {Promise<void>} A Promise that resolves when all pages have been loaded.
   */
  const loadNextPage = async () => {
    await dispatch(getAgenciesNextPage()).then(async ({ hasNext }) => {
      if (hasNext) await loadNextPage()
    })
  }

  async function loadAllAgencies() {
    await dispatch(getAgencies()).then(async ({ hasNext }) => {
      if (hasNext) await loadNextPage()
    })
  }

  const fetchMessageDetails = async () => {
    if (!Object.keys(messageDetails).length) {
      await dispatch(getMessagingDetails(messageId))
        .then(({ agencies: dataAgencies, ...rest }) => {
          setMessageDetails({
            ...rest,
            agencies: dataAgencies.map(agency => ({ id: agency.id, name: agency.name })),
          })
        })
        .catch(() => {
          setDialogInfo({
            isOpen: true,
            iconType: 'error',
            icon: InfoDangerIcon,
            labelApprove: 'Ok',
            subject: 'Erro',
            description:
              'Ocorreu um erro ao tentar carregar os dados da mensagem. Ela provavelmente não existe ou há algo de errado com o servidor.',
            onApprove: () => navigate(ROUTES.STEP_MESSAGE),
          })
          setIsLoading(false)
        })
    }
  }

  const loadData = async () => {
    setIsLoading(true)
    const promises = getListOfParameterFetches()

    if (messageId) promises.push(() => fetchMessageDetails())

    await Promise.all(promises.map(p => p()))
      .then(() => setIsLoading(false))
      .catch(() => {
        setIsLoading(false)
        showToast({ type: 'error', message: 'Ocorreu um erro ao carregar os dados.' })
      })
  }

  useEffect(() => {
    !isLoading && loadData().catch(() => {})
  }, [])

  return (
    <>
      <main className={styles.container}>
        <HeaderTitle
          title="Configuração de Mensageria por Etapa"
          backButtonAction={() => navigate(ROUTES.STEP_MESSAGE)}
        >
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button
              style={{ width: '100px' }}
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              Salvar
            </Button>
            {messageId && (
              <Button
                style={{ width: '100px' }}
                color="red"
                variant="primary"
                onClick={handleDelete}
                disabled={isLoading}
              >
                Deletar
              </Button>
            )}
          </div>
        </HeaderTitle>

        <div className={styles.content}>
          <FormProvider {...methods}>
            <form
              id="stepNotificationMessageSetupForm"
              style={{ gridTemplateAreas, gridTemplateColumns: 'repeat(6, 1fr)' }}
              className={styles.form}
            >
              <SectionTitle title="Geral" style={{ gridArea: 'generalSectionTitle' }} />

              <Autocomplete
                required
                label="Fluxo"
                name="business_front"
                defaultValue={messageDetails?.businessFront || null}
                options={
                  businessFrontList?.slice()?.sort((a, b) => a.name.localeCompare(b.name)) || []
                }
                getOptionLabel={option => option.description}
                style={{ gridArea: 'businessFront', justifyContent: 'flex-start' }}
              />
              <RadioSelect
                label="Tipo"
                name="type_message"
                options={typeMessageOptions}
                defaultValue={messageDetails?.typeMessage || null}
                required
                style={{ gridArea: 'type', marginBottom: '35px' }}
                onChange={handleTypeChange}
              />

              <Select
                label="Destino"
                name="destination"
                options={roleGroupOptions}
                getValue={item => item.roleGroupName}
                getLabel={item => item.roleGroupDesc}
                getKey={item => item.roleGroupName}
                menuProps={menuProps}
                defaultValue={messageDetails?.destination?.name || null}
                required
                style={{ gridArea: 'destination' }}
              />
              <Select
                label="Etapa"
                name="step_status"
                options={
                  stepStatusList?.slice()?.sort((a, b) => a.desc.localeCompare(b.desc)) || []
                }
                getValue={item => item.desc}
                getKey={item => item.id}
                menuProps={menuProps}
                defaultValue={stepStatusValue}
                required={typeSelected !== 'self_checkout' && typeSelected !== 'early_warning'}
                style={{ gridArea: 'stepStatus' }}
                disabled={isDisabled}
              />

              <Autocomplete
                options={
                  agencies
                    ?.map(agency => ({ id: agency.id, name: agency.name }))
                    ?.sort((a, b) => a.name.localeCompare(b.name)) || []
                }
                getOptionLabel={option => option.name}
                label="Intermediárias"
                name="agencies"
                required
                disabled={disableAgencies}
                defaultValue={agenciesDefaultValue}
                loading={!agencies?.length}
                style={{ gridArea: 'agencies', justifyContent: 'flex-start' }}
              />
              <Select
                label="Item definido"
                name="set_item"
                options={setItemOptions}
                getValue={item => item.label}
                getKey={item => item.value}
                menuProps={menuProps}
                defaultValue={setItemDefaultValue}
                required={typeSelected !== 'self_checkout' && typeSelected !== 'early_warning'}
                style={{ gridArea: 'definedItem', marginTop: '5px' }}
              />
              <Checkbox
                id="default"
                label="Default"
                name="default"
                defaultValue={defaultCheckboxDefaultValue}
                style={{
                  gridArea: 'defaultMessage',
                  marginTop: '-20px',
                  display: isSaasProfile ? 'none' : 'flex',
                }}
              />

              {(showSectionIndex === 0 || showSectionIndex === 2) && (
                <EmailSection messageDetails={messageDetails} styles={styles} />
              )}

              {showSectionIndex > 0 && (
                <WhatsAppSection messageDetails={messageDetails} selectedType={typeSelected} />
              )}
            </form>
          </FormProvider>
        </div>
      </main>
      {dialogInfo.isOpen && (
        <Dialog
          open={dialogInfo.isOpen}
          type={dialogInfo.iconType}
          icon={dialogInfo.icon}
          subject={dialogInfo.subject}
          description={dialogInfo.description}
          labelApprove={dialogInfo.labelApprove}
          onApprove={dialogInfo.onApprove}
          labelCancel={dialogInfo.labelCancel}
          onCancel={dialogInfo.onCancel}
        />
      )}
      <Toast draggable open={toast.isOpen} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert severity={toast.type} title={toast.message} onClose={handleCloseToast} />
      </Toast>
      <Loader hasBackdrop open={isLoading} label="Aguarde..." />
    </>
  )
}
